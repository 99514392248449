.search-modal {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: -100%;
    background: rgba(21, 22, 24, 0.98);
    z-index: 9999;
    opacity:0;
    transition: all ease 0.5s;
    .modal-content {
        position: initial;
        border: 0;
        background: transparent;
    }
    .search-block {
        input {
            height: 60px;
            line-height: 60px;
            padding: 0 15px;
            background: transparent;
            border-width: 0 0 1px 0;
            border-radius: 0;
            border-color: rgba(255,255,255,0.4);
            box-shadow: none;
            color: #ffffff;
            font-weight: 600;
            font-size: 18px;                
        }
        ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: #ffffff;
            opacity: 1;
        }
        ::-moz-placeholder { /* Firefox 19+ */
            color:#ffffff;
            opacity: 1;
        }
        :-ms-input-placeholder { /* IE 10+ */
            color: #ffffff;
            opacity: 1;
        }
        :-moz-placeholder { /* Firefox 18- */
            color: #ffffff;
            opacity: 1;
        }
    }
    .close {
        position: absolute;
        right: 50px;
        top: 50px;
        background: #ffffff;
        color: #0a0a0a;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
        border-radius: 50%;
        transition: all ease .5s;
        font-size: 18px;
        img {
            height: 15px;
            transition: all ease 0.5s;
        }
        &:hover {
            img {
                transform: rotate(180deg);
            }
        }            
    }
}
body {
    &.modal-open {
        .search-modal {
            top: 0;
            opacity: 1;
            position: fixed;
            .close {
                position: fixed;
            }
        }
    }
}